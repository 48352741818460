import DrawerEmailIcon from "assets/icon/DrawerEmailIcon";
import DrawerLogIcon from "assets/icon/DrawerLogIcon";
import DrawerVdoIcon from "assets/icon/DrawerVdoIcon";
import DrawerSupplementalIcon from "assets/icon/DrawerSupplementalIcon";
import DrawerWebinarIcon from "assets/icon/DrawerWebinarIcon";
import DrawerUserIcon from "assets/icon/DrawerUserIcon";
import { IconProps } from "assets/icon/IconType";
import DrawerExamResultIcon from "assets/icon/DrawerExamResultIcon";
import DrawerExamCardIcon from "assets/icon/DrawerExamCardIcon";
import { showExamCard } from "constant";
import {
  RegisterStatus,
  StudentStatus,
} from "../../authentication/service/registration";
import { useAuth, UserRole } from "../../authentication/service/useAuth";
import { useExamResults } from "../../exam/exam";

export const appPath = {
  login: "/login",
  forgotPassword: "/forgot-password",
  register: "/register",
  registerDetail: "/register-detail",
  examinationField: "/examination-field",
  receipt: "/receipt",
  examCard: "/exam-card",
  examResults: "/exam",
  user: "/user",
  manageUsers: "/users",
  manageAdminUsers: "/admin-users",
  email: "/email",
  webinar: "/webinar",
  manageWebinar: "/manage/webinar",
  fundamentalSurgery: "/fundamental-surgery",
  manageFundamentalSurgery: "/manage/fundamental-surgery",
  supplemental: "/supplemental-material",
  manageSupplemental: "/manage/supplemental-material",
  manageExam: "/manage/exam",
  log: "/users-log",
  newAcademicYear: "/new-academic-year",
  home: "/home",
  homeAdmin: "/home-admin",
};

export type NavigationDetail = {
  label: string;
  path: string;
  icon?: React.FunctionComponent<IconProps>;
};

type AppPath = keyof typeof appPath;

export const navigationDetail: Record<AppPath, NavigationDetail> = {
  login: {
    label: "SIGN IN",
    path: "/login",
  },
  forgotPassword: {
    label: "",
    path: "/forgot-password",
  },
  register: {
    label: "REGISTER",
    path: "/register",
  },
  registerDetail: {
    label: "",
    path: "/register-detail",
  },
  examinationField: {
    label: "",
    path: "/examination-field",
  },
  receipt: {
    label: "",
    path: "/receipt",
  },
  home: {
    label: "",
    path: "/home",
  },
  homeAdmin: {
    label: "",
    path: "/home-admin",
  },
  examCard: {
    label: "บัตรเข้าห้องสอบ",
    path: "/exam-card",
    icon: ({ admin, active }: IconProps) => (
      <DrawerExamCardIcon admin={admin} active={active} />
    ),
  },
  examResults: {
    label: "ประกาศผลสอบ",
    path: "/exam",
    icon: ({ admin, active }: IconProps) => (
      <DrawerExamResultIcon admin={admin} active={active} />
    ),
  },
  user: {
    label: "ข้อมูลผู้ลงทะเบียน",
    path: "/user",
    icon: ({ admin, active }: IconProps) => (
      <DrawerUserIcon admin={admin} active={active} />
    ),
  },
  manageUsers: {
    label: "User Management",
    path: "/users",
    icon: ({ admin, active }: IconProps) => (
      <DrawerUserIcon admin={admin} active={active} />
    ),
  },
  manageAdminUsers: {
    label: "Admin Management",
    path: "/admin-users",
    icon: ({ admin, active }: IconProps) => (
      <DrawerUserIcon admin={admin} active={active} />
    ),
  },
  email: {
    label: "Email",
    path: "/email",
    icon: ({ admin, active }: IconProps) => (
      <DrawerEmailIcon admin={admin} active={active} />
    ),
  },
  webinar: {
    label: "Webinar",
    path: "/webinar",
    icon: ({ admin, active }: IconProps) => (
      <DrawerWebinarIcon admin={admin} active={active} />
    ),
  },
  manageWebinar: {
    label: "Webinar",
    path: "/manage/webinar",
    icon: ({ admin, active }: IconProps) => (
      <DrawerWebinarIcon admin={admin} active={active} />
    ),
  },
  fundamentalSurgery: {
    label: "Fundamental Surgery",
    path: "/fundamental-surgery",
    icon: ({ admin, active }: IconProps) => (
      <DrawerVdoIcon admin={admin} active={active} />
    ),
  },
  manageFundamentalSurgery: {
    label: "Fundamental Surgery",
    path: "/manage/fundamental-surgery",
    icon: ({ admin, active }: IconProps) => (
      <DrawerVdoIcon admin={admin} active={active} />
    ),
  },
  supplemental: {
    label: "Supplemental Material",
    path: "/supplemental-material",
    icon: ({ admin, active }: IconProps) => (
      <DrawerSupplementalIcon admin={admin} active={active} />
    ),
  },
  manageSupplemental: {
    label: "Supplemental Material",
    path: "/manage/supplemental-material",
    icon: ({ admin, active }: IconProps) => (
      <DrawerSupplementalIcon admin={admin} active={active} />
    ),
  },
  manageExam: {
    label: "ประกาศผลสอบ",
    path: "/manage/exam",
    icon: ({ admin, active }: IconProps) => (
      <DrawerExamCardIcon admin={admin} active={active} />
    ),
  },
  log: {
    label: "Log Page",
    path: "/users-log",
    icon: ({ admin, active }: IconProps) => (
      <DrawerLogIcon admin={admin} active={active} />
    ),
  },
  newAcademicYear: {
    label: "เริ่มต้นปีการศึกษาใหม่",
    path: "/new-academic-year",
    icon: ({ admin, active }: IconProps) => (
      <DrawerWebinarIcon admin={admin} active={active} />
    ),
  },
};

export type AppNavigation = keyof typeof navigationDetail;

export type ProfileNavigation = {
  label: string;
  path: string;
  icon?: React.FunctionComponent<IconProps>;
};

export const getNavigationList = (
  role: UserRole,
  registerStatus: RegisterStatus,
  studentStatus: StudentStatus | undefined
): AppNavigation[] => {
  if (
    role === "user" &&
    (registerStatus !== "Registration Required" || studentStatus === "alumni")
  ) {
    return ["user"];
  }

  if (role === "admin") {
    return ["manageUsers", "manageAdminUsers", "newAcademicYear"];
  }

  return [];
};

const getPathsWhenEnabled = ({
  enabled,
  paths,
}: {
  enabled: boolean;
  paths: AppNavigation[];
}): AppNavigation[] => {
  return enabled ? paths : [];
};

export const getAppbarNavigationList = (
  role: UserRole,
  registerStatus: RegisterStatus,
  hasExamResults: boolean,
  studentStatus?: StudentStatus
): AppNavigation[] => {
  if (
    role === "user" &&
    (registerStatus !== "Registration Required" || studentStatus === "alumni")
  ) {
    const paths: AppNavigation[] =
      registerStatus === "Approved"
        ? ["webinar", "fundamentalSurgery", "supplemental"]
        : ["fundamentalSurgery", "supplemental"];

    return [
      ...getPathsWhenEnabled({
        enabled: registerStatus === "Approved" || studentStatus === "alumni",
        paths,
      }),
      ...(showExamCard ? ["examCard" as AppNavigation] : []),
      ...(hasExamResults || studentStatus === "alumni"
        ? ["examResults" as AppNavigation]
        : []),
    ];
  }

  if (role === "admin") {
    return [
      "email",
      "manageWebinar",
      "manageFundamentalSurgery",
      "manageSupplemental",
      "manageExam",
      "log",
    ];
  }

  return [];
};

export const getDrawerNavigationList = (
  role: UserRole,
  registerStatus: RegisterStatus,
  hasExamResults: boolean,
  studentStatus?: StudentStatus
): AppNavigation[] => {
  if (
    role === "user" &&
    (registerStatus !== "Registration Required" || studentStatus === "alumni")
  ) {
    const paths: AppNavigation[] =
      registerStatus === "Approved"
        ? ["webinar", "fundamentalSurgery", "supplemental"]
        : ["fundamentalSurgery", "supplemental"];
    return [
      "user",
      ...getPathsWhenEnabled({
        enabled: registerStatus === "Approved" || studentStatus === "alumni",
        paths,
      }),
      ...(showExamCard ? ["examCard" as AppNavigation] : []),
      ...(hasExamResults || studentStatus === "alumni"
        ? ["examResults" as AppNavigation]
        : []),
    ];
  }

  if (role === "admin") {
    return [
      "manageUsers",
      "manageAdminUsers",
      "newAcademicYear",
      "manageWebinar",
      "manageFundamentalSurgery",
      "manageSupplemental",
      "email",
      "manageExam",
      "log",
    ];
  }

  return [];
};

export const useAppNavigation = (): {
  navigationList: AppNavigation[];
  drawerNavigationList: AppNavigation[];
  appBarNavigationList: AppNavigation[];
} => {
  const { role, registerStatus, studentStatus } = useAuth();
  const examResults = useExamResults(role !== "user");
  const hasExamResults = Boolean(examResults?.examResult?.user?.email);

  const drawerNavigationList =
    role && registerStatus
      ? getDrawerNavigationList(
          role,
          registerStatus,
          hasExamResults,
          studentStatus
        )
      : [];

  const navigationList =
    role && registerStatus
      ? getNavigationList(role, registerStatus, studentStatus)
      : [];
  const appBarNavigationList =
    role && registerStatus
      ? getAppbarNavigationList(
          role,
          registerStatus,
          hasExamResults,
          studentStatus
        )
      : [];

  return {
    drawerNavigationList,
    navigationList,
    appBarNavigationList,
  };
};
