import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
} from "firebase/auth"
import firebaseApp from "../../firebase"

export const firebaseAuth = getAuth(firebaseApp)

export const signin = async (
  email: string,
  password: string,
  keepSignedIn: boolean
): Promise<UserCredential> => {
  await setPersistence(
    firebaseAuth,
    keepSignedIn ? browserLocalPersistence : browserSessionPersistence
  )
  return signInWithEmailAndPassword(firebaseAuth, email, password)
}

export const resetPassword = async (email: string): Promise<void> => {
  return sendPasswordResetEmail(firebaseAuth, email)
}

export const getCurrentUser = (): User | null => {
  return firebaseAuth.currentUser
}

export const getToken = async (): Promise<string | undefined> => {
  return firebaseAuth.currentUser?.getIdToken()
}

export const logout = async (): Promise<void> => {
  return signOut(firebaseAuth)
}
