// import { sub } from "date-fns"

export const registrationStartDate = new Date("08/01/2022")
export const registrationEndDate = new Date("10/10/2022")

export const examDates = [
  new Date("11/05/2022"),
  new Date("02/11/2023"),
  new Date("05/06/2023"),
]

// export const showExamCard = new Date() >= sub(examDates[0], { weeks: 2 })
export const showExamCard = true // Boat told us to just show the exam card immediately
