/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react"
import React from "react"

type Props = {
  image: string
  text: string
}

const Signature = ({ image, text }: Props) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "min-content",
      }}
    >
      <img src={image} alt="recipient signature" height={60} />
      <div
        css={{
          marginTop: "2px",
          height: "2px",
          width: "100%",
          background:
            "repeating-linear-gradient(to right,#929292 0,#929292 10px,transparent 10px,transparent 12px)",
        }}
      />
      <p css={{ fontFamily: "CordiaNew", fontSize: "28px" }}>{text}</p>
    </div>
  )
}

export default Signature
