/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import ThemeColor from "theme/color";
import { ThaiBaht } from "thai-baht-text-ts";
import { UserDetail } from "../../service/userService";
import { ReceiptType, receiptText } from "./text";

type Props = {
  type: ReceiptType;
  user: UserDetail;
  price: number;
  paymentType: string;
};

const Content = ({ user, type, price, paymentType }: Props) => {
  return (
    <div
      css={{
        border: "1px solid #D0E0EFDD",
        width: "750px",
        borderRadius: "12px",
        backgroundColor: ThemeColor.lighterBlueBackground,
        margin: "16px auto",
        padding: "20px 34px 20px 56px",
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        columnGap: "20px",
        rowGap: "8px",
      }}
    >
      <p css={{ fontFamily: "CordiaNew", fontSize: "28px" }}>ได้รับเงินจาก</p>
      <p
        css={{
          fontFamily: "CordiaNew",
          fontSize: "28px",
          borderBottom: "1px solid black",
        }}
      >
        {user.namePrefix} {user.nameTh}
      </p>

      <div />
      <p
        css={{
          fontFamily: "CordiaNew",
          fontSize: "28px",
          borderBottom: "1px solid black",
        }}
      >
        {user.currentHospital}
      </p>

      <p css={{ fontFamily: "CordiaNew", fontSize: "28px" }}>ชำระเงิน</p>
      <p
        css={{
          fontFamily: "CordiaNew",
          fontSize: "28px",
          borderBottom: "1px solid black",
        }}
      >
        {paymentType}
      </p>

      <p css={{ fontFamily: "CordiaNew", fontSize: "28px" }}>จำนวน</p>
      <div css={{ display: "flex", flexDirection: "row", gap: "8px", flex: 1 }}>
        <p
          css={{
            fontFamily: "CordiaNew",
            fontSize: "28px",
            borderBottom: "1px solid black",
            width: "160px",
            textAlign: "center",
          }}
        >
          {price.toLocaleString("en-US")}
        </p>
        <p css={{ fontFamily: "CordiaNew", fontSize: "28px" }}>บาท</p>
        <p
          css={{
            fontFamily: "CordiaNew",
            fontSize: "28px",
            borderBottom: "1px solid black",
            flex: 1,
            textAlign: "center",
          }}
        >
          {ThaiBaht(price)}
        </p>
      </div>
    </div>
  );
};

export default Content;
