/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import HeaderLogo from "assets/logo/receipt_header_logo.png";
import { ReceiptType, receiptText } from "./text";

type Props = {
  type: ReceiptType;
  academicYear: number;
  studentId: string;
};

const Header = ({ type, academicYear, studentId }: Props) => {
  return (
    <div css={{ position: "relative", width: "100%" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <img src={HeaderLogo} alt="header" css={{ width: "760px" }} />
        <div css={{ marginTop: "30px", marginRight: "30px" }}>
          เลขที่ {type === "training" ? `W` : `T`}
          {`${academicYear}/${studentId}`}
        </div>
      </div>
      <div css={{ height: "48px" }} />
      <div
        css={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
          textAlign: "center",
        }}
      >
        <p css={{ fontFamily: "CordiaNew", fontWeight: 700, fontSize: "28px" }}>
          Receipt/Registration
        </p>
        <p css={{ fontFamily: "CordiaNew", fontSize: "22px" }}>
          ใบเสร็จรับเงิน/ใบลงทะเบียน
        </p>
        <p css={{ fontFamily: "CordiaNew", fontSize: "22px", fontWeight: 700 }}>
          {receiptText[type].name}
        </p>
        <p css={{ fontFamily: "CordiaNew", fontSize: "22px" }}>
          ปีการศึกษา {academicYear} - {academicYear + 1}
        </p>
      </div>
    </div>
  );
};

export default Header;
