import { SvgIcon } from "@mui/material"
import React from "react"
import { IconProps } from "./IconType"

const DrawerLogIcon = ({ admin, active = false }: IconProps) => {
  const inactiveColor = admin ? "#fff" : "#9da1b2"
  const activeColor = admin ? "#fff" : "#5694D8"
  return (
    <SvgIcon viewBox="0 0 14.819 17.223" fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.819"
        height="17.223"
        viewBox="0 0 14.819 17.223"
      >
        <path
          data-name="Path 5549"
          d="M0 16.849V.374A.375.375 0 0 1 .374 0h1.322a.373.373 0 0 1 .374.376v.719A2.345 2.345 0 0 0 4.507 3.53q2.894.036 5.789 0a2.331 2.331 0 0 0 2.427-2.5V.392a.376.376 0 0 1 .375-.376h1.342a.374.374 0 0 1 .374.374v16.459a.375.375 0 0 1-.374.374H.374A.375.375 0 0 1 0 16.849M11.865 5.968h-8.9a.374.374 0 0 0-.374.374v.449a.373.373 0 0 0 .374.374h8.9a.373.373 0 0 0 .374-.374v-.449a.374.374 0 0 0-.374-.374m.008 2.541H2.962a.373.373 0 0 0-.374.374v.4a.373.373 0 0 0 .374.374h8.911a.374.374 0 0 0 .374-.374v-.4a.374.374 0 0 0-.374-.374m-.011 2.483h-8.9a.374.374 0 0 0-.375.374v.434a.374.374 0 0 0 .375.374h8.9a.374.374 0 0 0 .375-.374v-.434a.374.374 0 0 0-.375-.374m-8.92 3.671h8.916a.375.375 0 0 0 .374-.374v-.435a.375.375 0 0 0-.374-.374H2.942a.374.374 0 0 0-.374.374v.435a.374.374 0 0 0 .374.374"
          fill={active ? activeColor : inactiveColor}
        />
      </svg>
    </SvgIcon>
  )
}

export default DrawerLogIcon
