export const hospitals = [
  "คณะแพทยศาสตร์จุฬาลงกรณ์มหาวิทยาลัย",
  "คณะแพทยศาสตร์มหาวิทยาลัยขอนแก่น",
  "คณะแพทยศาสตร์มหาวิทยาลัยเชียงใหม่",
  "คณะแพทยศาสตร์มหาวิทยาลัยธรรมศาสตร์ ศูนย์รังสิต",
  "คณะแพทยศาสตร์มหาวิทยาลัยนเรศวร",
  "คณะแพทยศาสตร์มหาวิทยาลัยศรีนครินทรวิโรฒ ",
  "คณะแพทยศาสตร์มหาวิทยาลัยสงขลานครินทร์",
  "คณะแพทยศาสตร์โรงพยาบาลรามาธิบดี",
  "คณะแพทยศาสตร์วชิรพยาบาล  มหาวิทยาลัยนวมินทราธิราช",
  "คณะแพทยศาสตร์ศิริราชพยาบาล",
  "โรงพยาบาลกลาง",
  "โรงพยาบาลเจริญกรุงประชารักษ์",
  "โรงพยาบาลชลบุรี",
  "โรงพยาบาลเชียงรายประชานุเคราะห์",
  "โรงพยาบาลตากสิน",
  "โรงพยาบาลตำรวจ",
  "โรงพยาบาลนครปฐม",
  "โรงพยาบาลนครพิงค์",
  "โรงพยาบาลบุรีรัมย์",
  "โรงพยาบาลพระปกเกล้า",
  "โรงพยาบาลพระมงกุฎเกล้า ",
  "โรงพยาบาลพุทธชินราช",
  "โรงพยาบาลภูมิพลอดุลยเดช",
  "โรงพยาบาลมหาราชนครราชสีมา",
  "โรงพยาบาลร้อยเอ็ด",
  "โรงพยาบาลราชวิถี",
  "โรงพยาบาลลำปาง",
  "โรงพยาบาลเลิดสิน",
  "โรงพยาบาลศูนย์ขอนแก่น",
  "โรงพยาบาลศูนย์ยะลา",
  "โรงพยาบาลสมเด็จพระบรมราชเทวี  ณ ศรีราชา",
  "โรงพยาบาลสมเด็จพระปิ่นเกล้า",
  "โรงพยาบาลสรรพสิทธิประสงค์",
  "โรงพยาบาลสระบุรี",
  "โรงพยาบาลสุราษฎร์ธานี",
  "โรงพยาบาลสุรินทร์",
  "โรงพยาบาลสวรรค์ประชารักษ์",
  "โรงพยาบาลหาดใหญ่",
  "โรงพยาบาลอุดรธานี",
  "สถาบันประสาทวิทยา",
  "สถาบันโรคทรวงอก",
  "สถาบันมะเร็งแห่งชาติ",
  "สถาบันสุขภาพเด็กแห่งชาติมหาราชินี",
  "โรงพยาบาลอื่น ๆ",
]

export const careerStatusTypes = [
  "แพทย์ประจำบ้าน",
  "แพทย์ใช้ทุน",
  "ในปัจจุบันยังไม่ได้อยู่ในหลักสูตรฝึกอบรม แต่ได้รับการตอบรับเข้ารับการอบรมในสถาบัน (กรุณาระบุชื่อสถาบัน)",
  "ในปัจจุบันยังไม่ได้อยู่ในหลักสูตรฝึกอบรม แต่มีแผนสมัครเข้ารับการฝึกอบรมในสถาบัน (กรุณาระบุชื่อสถาบัน)",
  "ในปัจจุบันยังไม่ได้อยู่ในหลักสูตรฝึกอบรม และยังไม่มีแผนสมัครเข้ารับการอบรม",
]

export const currentSpecialtyTypes = [
  "ศัลยศาสตร์ทั่วไป",
  "กุมารศัลยศาสตร์",
  "ศัลยศาสตร์ตกแต่ง",
  "ศัลยศาสตร์ทรวงอก",
  // "ประสาทศัลยศาสตร์",
  "ศัลยศาสตร์ยูโรวิทยา",
  // "ศัลยศาสตร์ลำไส้ใหญ่และทวารหนัก",
  // "ศัลยศาสตร์ออร์โธปิดิกส์",
  // "ศัลยศาสตร์หลอดเลือด",
  // "ศัลยศาสตร์มะเร็งวิทยา",
  // "ศัลยศาสตร์อุบัติเหตุ",
]
