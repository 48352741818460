/** @jsxRuntime classic */
/** @jsx  jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { AppNavigation, navigationDetail } from "./navigation";
import Profile from "./Profile";

type ProfileMenuProps = {
  navigations: AppNavigation[];
  onLogout: () => void;
};
const ProfileMenu = ({
  navigations,
  onLogout,
}: ProfileMenuProps): JSX.Element => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string) => {
    history.push(path);
    handleClose();
  };

  const handleLogout = async () => {
    onLogout();
    handleClose();
  };

  return (
    <>
      <Button
        type="button"
        css={{
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          color: "white",
          alignItems: "center",
          columnGap: "12px",
          textTransform: "none",
          marginLeft: "20px",
        }}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <Profile />
      </Button>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "profile button",
        }}
        css={{
          borderRadius: "0 !important",
        }}
      >
        {navigations.map((navigation) => (
          <MenuItem
            key={navigationDetail[navigation].path}
            onClick={() => handleNavigate(navigationDetail[navigation].path)}
          >
            {navigationDetail[navigation].label}
          </MenuItem>
        ))}
        <MenuItem onClick={handleLogout}>Log out</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
