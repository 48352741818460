import { SvgIcon } from "@mui/material"
import React from "react"
import { IconProps } from "./IconType"

const DrawerEmailIcon = ({ admin, active = false }: IconProps) => {
  const inactiveColor = admin ? "#fff" : "#9da1b2"
  const activeColor = admin ? "#fff" : "#5694D8"
  return (
    <SvgIcon viewBox="0 0 20.193 13.373" fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.193"
        height="13.373"
        viewBox="0 0 20.193 13.373"
      >
        <path
          data-name="Path 5541"
          d="M10.098 13.359c-2.79 0-5.582-.026-8.371.014A1.62 1.62 0 0 1 0 11.892C.035 8.428.016 4.963.016 1.499c0-.939.642-1.5 1.722-1.5h16.743c1.057 0 1.711.563 1.711 1.475V11.9c0 .9-.645 1.457-1.684 1.458h-8.41m-8.2-12.309c.116.117.192.2.274.275Q4.959 3.9 7.748 6.472a3.429 3.429 0 0 0 4.714-.005q2.808-2.58 5.6-5.173c.074-.068.141-.141.244-.245ZM18.226 12.34 13.71 6.807a4.868 4.868 0 0 1-7.142.08L1.912 12.34ZM1.242 1.967V11.4l4.5-5.274-4.5-4.159m13.3 4.082 4.427 5.421V1.961l-4.427 4.088"
          fill={active ? activeColor : inactiveColor}
        />
      </svg>
    </SvgIcon>
  )
}

export default DrawerEmailIcon
