import { SvgIcon } from "@mui/material"
import React from "react"
import { IconProps } from "./IconType"

const DrawerExamResultIcon = ({ admin, active }: IconProps) => {
  const inactiveColor = admin ? "#fff" : "#9da1b2"
  const activeColor = admin ? "#fff" : "#5694D8"
  return (
    <SvgIcon viewBox="0 0 51 40.048" fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="51"
        height="40.048"
        viewBox="0 0 51 40.048"
        fill={active ? activeColor : inactiveColor}
      >
        <g transform="translate(-8295.341 -1005.883)">
          <path d="M8295.341,1023.726a2.176,2.176,0,0,1,2.336-1.075c.9.127,1.339-.132,1.487-1.139a17.105,17.105,0,0,1,.984-3.308,1.1,1.1,0,0,1,.8-.6c3.632-.04,7.264-.026,10.977-.026v15.028h-3.1c.067,1.628.072,1.608,1.421,1.777a.936.936,0,0,1,.639.531c.271.844.453,1.717.691,2.672l-1.616.075,2.04,8.267h-4.957q-1.326-5.305-2.657-10.61c-.216-.857-.224-2.015-.784-2.467s-1.686-.225-2.56-.186a.822.822,0,0,1-.953-.725c-.319-1.138-.724-2.257-.962-3.41-.15-.728-.426-1.017-1.166-.929a2.362,2.362,0,0,1-2.615-1.1Z" />
          <path d="M8346.341,1025.907a4.814,4.814,0,0,1-4.36,3.585v2.067c0,3.6.036,7.2-.036,10.8a2.57,2.57,0,0,1-.8,1.745c-.761.62-2.492-.5-2.6-1.593-.028-.295-.007-.594-.007-.891,0-10.831.036-21.662-.042-32.492a3.322,3.322,0,0,1,1.7-3.241h.594a1.8,1.8,0,0,1,1.2,1.932c-.021,2.742-.007,5.484-.007,8.226v5.022c2.451.5,3.3,1.181,4.36,3.456Z" />
          <path d="M8314.537,1017.595c8.258-.088,15.514-2.629,21.649-8.45v31.944c-6.1-5.787-13.372-8.348-21.649-8.441Z" />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default DrawerExamResultIcon
