/** @jsxRuntime classic */
/** @jsx  jsx */
import { jsx } from "@emotion/react"
import React from "react"
import PageWrapper from "./PageWrapper"

type Props = {}

const LoadingPage = (props: Props) => {
  return (
    <PageWrapper css={{ display: "flex", alignItems: "center" }}>
      Loading...
    </PageWrapper>
  )
}

export default LoadingPage
