import useSWR, { mutate } from "swr";
import client from "apiClient";
import { ExaminationDetails, AcademicYearData } from "./type";

export type Handout = { link: string };

const apiPathExaminationDetails = "/examination-round-pricing";
const apiPathStartNewAcademicYear = "/start-new-semester";
const apiPathCurrentAcademicYear = "/examination-year/current";
export const storagePath = "assets%2Ffundamental-surgery%2Fhandouts%2F";

const defaultExaminationDetailsData = {
  round1Price: {
    price: 0,
    startDate: null,
    endDate: null,
  },
  round2Price: {
    price: 0,
    startDate: null,
    endDate: null,
  },
  round3Price: {
    price: 0,
    startDate: null,
    endDate: null,
  },
  newComer: {
    priceSeminar: 0,
    priceExamination: 0,
    startDate: null,
    endDate: null,
  },
};

export const useExaminationDetails = (): ExaminationDetails => {
  const { data } = useSWR(apiPathExaminationDetails, (url) =>
    // The result somehow return round1price instead of round1Price.
    client.get(url).then((res) => {
      return {
        round1Price: {
          price: res.data.data.round1price.price,
          startDate: res.data.data.round1price.startDate,
          endDate: res.data.data.round1price.endDate,
        },
        round2Price: {
          price: res.data.data.round2price.price,
          startDate: res.data.data.round2price.startDate,
          endDate: res.data.data.round2price.endDate,
        },
        round3Price: {
          price: res.data.data.round3price.price,
          startDate: res.data.data.round3price.startDate,
          endDate: res.data.data.round3price.endDate,
        },
        newComer: {
          priceSeminar: res.data.data.newComer.priceSeminar,
          priceExamination: res.data.data.newComer.priceExamination,
          startDate: res.data.data.newComer.startDate,
          endDate: res.data.data.newComer.endDate,
        },
      };
    })
  );
  return data ?? defaultExaminationDetailsData;
};

export const useCurrentAcademicYear = (): number => {
  const { data } = useSWR(apiPathCurrentAcademicYear, (url) =>
    client.get(url).then((res) => res.data.data.year)
  );
  return data ?? 0;
};

export const updateExaminationDetails = async (
  examinationDetailsBody: ExaminationDetails
): Promise<void> => {
  await client.put(apiPathExaminationDetails, examinationDetailsBody);
  mutate(apiPathExaminationDetails);
};

export const startNewAcademicYear = async (
  nextAcademicYearBody: AcademicYearData
): Promise<void> => {
  await client.post(apiPathStartNewAcademicYear, nextAcademicYearBody);
  mutate(apiPathStartNewAcademicYear);
};
