import { useMemo } from "react";
import useSWR, { mutate } from "swr";
import client from "../../apiClient";
import {
  RegisterStatus,
  StudentStatus,
} from "../../authentication/service/registration";
import { useAuth } from "../../authentication/service/useAuth";
import { hospitals } from "../RegistrationConstant";

export const generateSeatNumber = async (): Promise<void> => {
  await client.post("generate-seat-no");

  mutate("users");
};

export interface UserDetailBase {
  uid: string;
  email: string;
  idCardNumber: string;
  namePrefix: string;
  nameTh: string;
  nameEn: string;
  medicalLicense: string;
  telephoneNo: string;
  address: string;
  currentHospital: string;
  currentTrainingSite: string;
  currentMedicalStatus: string;
  medicalInternshipYear: string;
  currentSpecialty: string;
  hospitalPlan: string;
  basicScienceExamination?: boolean;
  basicScienceExaminationTimes: string;
  basicScienceExaminationDate: string;
  profilePictures: Picture[];
  certificateOfTrainingInSurgeryPictures: Picture[];
  applicationPaymentReceipts: Picture[];
  vaccineCertificates: Picture[];
  seatNo?: string;
}

export type Picture = {
  url: string;
  created: string; // toISOString()
};

export type ExaminationField = {
  description: string;
  slug: string;
};

export type ApplicationType = {
  type: "only_training" | "only_examination" | "training_and_examination" | "";
  description: string;
};

export type RegisterStatusRequest = "waiting_for_approval" | "approved";

interface UserDetailRequestData extends UserDetailBase {
  createdAtExaminationYear: number;
  currentExaminationYear: number;
  examinationField: string;
  registerStatus?: RegisterStatusRequest;
  isAppliedRound1Examination: Boolean | null;
  isAppliedRound2Examination: Boolean | null;
  isAppliedRound3Examination: Boolean | null;
  isEmailUpdated?: boolean;
}

const defaultRequestData: UserDetailRequestData = {
  uid: "",
  email: "",
  idCardNumber: "",
  namePrefix: "",
  nameTh: "",
  nameEn: "",
  medicalLicense: "",
  telephoneNo: "",
  address: "",
  currentHospital: "",
  currentTrainingSite: "",
  currentMedicalStatus: "",
  medicalInternshipYear: "",
  currentSpecialty: "",
  hospitalPlan: "",
  basicScienceExamination: false,
  basicScienceExaminationTimes: "",
  basicScienceExaminationDate: "",
  profilePictures: [],
  certificateOfTrainingInSurgeryPictures: [],
  applicationPaymentReceipts: [],
  vaccineCertificates: [],
  createdAtExaminationYear: 0,
  currentExaminationYear: 0,
  examinationField: "",
  seatNo: "",
  isAppliedRound1Examination: null,
  isAppliedRound2Examination: null,
  isAppliedRound3Examination: null,
  isEmailUpdated: false,
};

export const register = async (form: UserDetailRequestData): Promise<void> => {
  if (form.isEmailUpdated) {
    const updateEmailUrl = `user/${form.uid}/email`;
    await client.put(updateEmailUrl, {
      email: form.email.toLocaleLowerCase(),
    });
    mutate(updateEmailUrl);
  }

  const newForm = {
    ...defaultRequestData,
    ...form,
  };

  const putUserUrl = "user?examination_register=true";

  await client.put(putUserUrl, newForm);

  mutate(`user/${form.uid}`);
  mutate("users");
};

export interface UserDetail extends UserDetailBase {
  applicationType: ApplicationType;
  examinationField?: ExaminationField;
  registerStatus?: RegisterStatus;
  studentStatus?: StudentStatus;
  createdAt?: string;
  isAppliedRound1Examination: boolean;
  isAppliedRound2Examination: boolean;
  isAppliedRound3Examination: boolean;
  createdAtExaminationYear: number;
  currentExaminationYear: number;
  studentId: string;
}

const defaultUser: UserDetail = {
  uid: "",
  email: "",
  idCardNumber: "",
  namePrefix: "",
  nameTh: "",
  nameEn: "",
  medicalLicense: "",
  telephoneNo: "",
  address: "",
  currentHospital: "",
  currentTrainingSite: "",
  currentMedicalStatus: "",
  medicalInternshipYear: "",
  currentSpecialty: "",
  hospitalPlan: "",
  basicScienceExamination: false,
  basicScienceExaminationTimes: "",
  basicScienceExaminationDate: "",
  profilePictures: [],
  certificateOfTrainingInSurgeryPictures: [],
  applicationPaymentReceipts: [],
  vaccineCertificates: [],
  applicationType: { type: "", description: "" },
  examinationField: undefined,
  seatNo: "",
  isAppliedRound1Examination: true,
  isAppliedRound2Examination: true,
  isAppliedRound3Examination: true,
  createdAtExaminationYear: 2565,
  currentExaminationYear: 2565,
  studentId: "",
};

export const mapUser = (data: any): UserDetail => {
  const examinationField = data?.examinationField?.description
    ? data?.examinationField
    : undefined;
  return {
    uid: data?.uid ?? "",
    email: data?.email ?? "",
    idCardNumber: data?.idCardNumber ?? "",
    namePrefix: data?.namePrefix ?? "",
    nameTh: data?.nameTh ?? "",
    nameEn: data?.nameEn ?? "",
    medicalLicense: data?.medicalLicense ?? "",
    telephoneNo: data?.telephoneNo ?? "",
    address: data?.address ?? "",
    currentHospital: data?.currentHospital ?? "",
    currentTrainingSite: data?.currentTrainingSite ?? "",
    currentMedicalStatus: data?.currentMedicalStatus ?? "",
    medicalInternshipYear: data?.medicalInternshipYear ?? "",
    currentSpecialty: data?.currentSpecialty ?? "",
    hospitalPlan: data?.hospitalPlan ?? "",
    basicScienceExamination: data?.basicScienceExamination ?? undefined,
    basicScienceExaminationTimes: data?.basicScienceExaminationTimes ?? "",
    basicScienceExaminationDate: data?.basicScienceExaminationDate ?? "",
    profilePictures: data?.profilePictures ?? [],
    certificateOfTrainingInSurgeryPictures:
      data?.certificateOfTrainingInSurgeryPictures ?? [],
    applicationPaymentReceipts: data?.applicationPaymentReceipts ?? [],
    vaccineCertificates: data?.vaccineCertificates ?? [],
    applicationType: data?.applicationType,
    examinationField,
    seatNo: data?.seatNo ?? "",
    registerStatus: data?.registerStatus,
    studentStatus: data?.studentStatus,
    createdAt: data?.createdAt,
    isAppliedRound1Examination: data?.isAppliedRound1Examination,
    isAppliedRound2Examination: data?.isAppliedRound2Examination,
    isAppliedRound3Examination: data?.isAppliedRound3Examination,
    createdAtExaminationYear: data?.createdAtExaminationYear,
    currentExaminationYear: data?.currentExaminationYear,
    studentId: data?.studentId,
  };
};

export const useUsers = (): UserDetail[] => {
  const { data } = useSWR("users", (url) =>
    client
      .get(url)
      .then((res) =>
        res.data.data.map(mapUser).filter(({ uid }: UserDetail) => uid)
      )
  );
  return useMemo(() => data ?? [], [data]);
};

export type UserFormData = {
  uid: string;
  email: string;
  idCardNumber: string;
  namePrefix: string;
  nameTh: string;
  nameEn: string;
  medicalLicense: string;
  telephoneNo: string;
  address: string;
  currentHospital: string;
  otherHospital: string;
  currentTrainingSite: string;
  currentMedicalStatus: string;
  medicalInternshipYear: string;
  currentSpecialty: string;
  hospitalPlan: string;
  hadBasicScienceExamination: string;
  basicScienceExaminationTimes: string;
  basicScienceExaminationDate: string;
  applicationType: string;
  examinationField: string;
  registerStatus: RegisterStatus;
  isAppliedRound1Examination: boolean;
  isAppliedRound2Examination: boolean;
  isAppliedRound3Examination: boolean;
};

const defaultFormData: UserFormData = {
  uid: "",
  email: "",
  idCardNumber: "",
  namePrefix: "",
  nameTh: "",
  nameEn: "",
  medicalLicense: "",
  telephoneNo: "",
  address: "",
  currentHospital: "",
  otherHospital: "",
  currentTrainingSite: "",
  currentMedicalStatus: "",
  medicalInternshipYear: "",
  currentSpecialty: "",
  hospitalPlan: "",
  hadBasicScienceExamination: "",
  basicScienceExaminationTimes: "",
  basicScienceExaminationDate: "",
  applicationType: "",
  examinationField: "",
  isAppliedRound1Examination: true,
  isAppliedRound2Examination: true,
  isAppliedRound3Examination: true,

  registerStatus: null,
};

type UseUser = {
  uid?: string;
};
export const useUser = ({ uid }: UseUser = {}): {
  userDetail: UserDetail;
  userFormData: UserFormData;
} => {
  const { status, user } = useAuth();
  const currentUid = uid ?? user?.uid;
  const { data } = useSWR(
    status === "success" && Boolean(currentUid) ? `user/${currentUid}` : null,
    (url) => client.get(url).then((res) => mapUser(res.data.data))
  );

  const currentHospital = useMemo(() => {
    if (!data?.currentHospital) return "";
    if (data.currentHospital && hospitals.includes(data.currentHospital)) {
      return data.currentHospital;
    }
    return "โรงพยาบาลอื่น ๆ";
  }, [data]);

  if (!data) return { userDetail: defaultUser, userFormData: defaultFormData };

  const hadBasicScienceExamination = data.basicScienceExamination
    ? "yes"
    : "no";

  const userFormData: UserFormData = {
    uid: data.uid ?? "",
    email: data.email ?? "",
    idCardNumber: data.idCardNumber ?? "",
    namePrefix: data.namePrefix ?? "",
    nameTh: data.nameTh ?? "",
    nameEn: data.nameEn ?? "",
    medicalLicense: data.medicalLicense ?? "",
    telephoneNo: data.telephoneNo ?? "",
    address: data.address ?? "",
    currentHospital,
    otherHospital:
      currentHospital === "โรงพยาบาลอื่น ๆ" ? data.currentHospital ?? "" : "",
    currentMedicalStatus: data.currentMedicalStatus ?? "",
    currentTrainingSite: data.currentTrainingSite ?? "",
    medicalInternshipYear: data.medicalInternshipYear ?? "",
    currentSpecialty: data.currentSpecialty ?? "",
    hospitalPlan: data.hospitalPlan ?? "",
    hadBasicScienceExamination:
      data.basicScienceExamination === null ? "" : hadBasicScienceExamination,
    basicScienceExaminationTimes: data.basicScienceExaminationTimes ?? "",
    basicScienceExaminationDate: data.basicScienceExaminationDate ?? "",
    applicationType: data.applicationType?.type ?? "",
    examinationField: data.examinationField?.slug ?? "",
    registerStatus: data.registerStatus ?? null,
    isAppliedRound1Examination: data.isAppliedRound1Examination ?? true,
    isAppliedRound2Examination: data.isAppliedRound2Examination ?? true,
    isAppliedRound3Examination: data.isAppliedRound3Examination ?? true,
  };

  return { userDetail: data, userFormData };
};
