/** @jsxRuntime classic */
/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/react";
import { AppBar, Button, useMediaQuery } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo/logo_horizontal.png";
import adminLogo from "../../assets/logo/logo_horizontal_admin.png";
import AppBarButtonGroup from "../../authentication/AppBarButtonGroup";
import { UserRole } from "../../authentication/service/useAuth";
import { appPath, useAppNavigation } from "./navigation";
import ProfileMenu from "./ProfileMenu";

type Props = {
  role?: UserRole;
  headerTabs?: React.ReactNode;
  onLogout: () => void;
};

const DesktopAppBar = ({ role, headerTabs, onLogout }: Props) => {
  const history = useHistory();
  // const isAppBarCollapsed = useMediaQuery("(max-width:1340px)")

  const { navigationList, appBarNavigationList } = useAppNavigation();

  const showAppBarTabs = Boolean(role);
  const hasHeaderTabs = headerTabs || showAppBarTabs;

  return (
    <AppBar
      css={{
        backgroundColor: role === "admin" ? "#0569C4" : undefined,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: role === "admin" ? undefined : "10px 24px",
        alignItems: "center",
        "@media(max-width: 1000px)": {
          padding: "16px 10px",
        },
        "@media print": {
          display: "none",
        },
      }}
    >
      {role === "admin" ? (
        <Button
          css={{
            backgroundColor: "#5694D8",
            padding: "10px 16px",
            borderTopRightRadius: "40px",
            borderBottomRightRadius: "40px",
          }}
          onClick={() => history.push(appPath.home)}
        >
          <img
            src={adminLogo}
            alt="logo"
            css={{
              height: "60px",
              width: "133px",
              "@media(max-width: 1000px)": {
                width: "66px",
                height: "30px",
              },
            }}
          />
        </Button>
      ) : (
        <Button onClick={() => history.push(appPath.home)}>
          <img
            src={logo}
            alt="logo"
            css={{
              height: "60px",
              width: "246px",
              "@media(max-width: 1000px)": {
                width: "123px",
                height: "30px",
              },
            }}
          />
        </Button>
      )}
      {hasHeaderTabs && (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {showAppBarTabs && appBarNavigationList.length ? (
            <AppBarButtonGroup
              color={role === "user" ? "primary" : "secondary"}
              appNavigations={appBarNavigationList}
            />
          ) : (
            headerTabs
          )}
          {showAppBarTabs && (
            <ProfileMenu navigations={navigationList} onLogout={onLogout} />
          )}
        </div>
      )}
    </AppBar>
  );
};

export default DesktopAppBar;
