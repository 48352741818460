import React from "react";
import { useParams } from "react-router-dom";
import {
  useCurrentAcademicYear,
  useExaminationDetails,
} from "newAcademicYear/newAcademicYear";
import LoadingPage from "../../../component/layout/LoadingPage";
import { useUser } from "../../service/userService";
import Receipt from "./Receipt";

const ReceiptPage = (): JSX.Element => {
  const params = useParams<{ id?: string }>();
  const { userDetail } = useUser({ uid: params?.id });
  const academicYear = useCurrentAcademicYear();
  const examinationDetails = useExaminationDetails();

  if (userDetail.registerStatus !== "Approved") {
    return <LoadingPage />;
  }

  if (userDetail.studentStatus === "alumni") {
    const priceRound1 = userDetail.isAppliedRound1Examination
      ? examinationDetails.round1Price.price
      : 0;
    const priceRound2 = userDetail.isAppliedRound2Examination
      ? examinationDetails.round2Price.price
      : 0;
    const priceRound3 = userDetail.isAppliedRound3Examination
      ? examinationDetails.round3Price.price
      : 0;
    return (
      <Receipt
        type="exam"
        user={userDetail}
        academicYear={academicYear}
        price={priceRound1 + priceRound2 + priceRound3}
        paymentType="ค่าลงทะเบียนสอบศัลยศาสตร์พื้นฐาน"
        studentId={userDetail.studentId}
      />
    );
  }

  return (
    <div>
      <Receipt
        type="exam"
        user={userDetail}
        academicYear={academicYear}
        price={examinationDetails.newComer.priceExamination}
        paymentType="ค่าลงทะเบียนสอบศัลยศาสตร์พื้นฐาน"
        studentId={userDetail.studentId}
      />
      <Receipt
        type="training"
        user={userDetail}
        academicYear={academicYear}
        price={examinationDetails.newComer.priceSeminar}
        paymentType="ค่าลงทะเบียนอบรมศัลยศาสตร์พื้นฐาน"
        studentId={userDetail.studentId}
      />
    </div>
  );
};

export default ReceiptPage;
