import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  RouteProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AuthState, useAuth } from "../../authentication/service/useAuth";
import LoadingPage from "./LoadingPage";
import { appPath } from "./navigation";

type CustomLocation = {
  from: { pathname: string };
};

type PrivateRouteProps = {
  admin?: boolean;
} & RouteProps;

export const getRedirectedRoute = (
  { status, user, role, registerStatus, studentStatus }: AuthState,
  pathname: string,
  admin?: boolean
): string | null => {
  if (status === "idle" || status === "pending") {
    return "loading";
  }
  if (!user) {
    return appPath.login;
  }

  if (!registerStatus && !studentStatus) {
    return "loading";
  }

  if (Boolean(admin) !== (role === "admin") || pathname === "/") {
    return role === "admin" ? appPath.homeAdmin : appPath.home;
  }

  if (
    role === "user" &&
    registerStatus !== "Registration Required" &&
    pathname === appPath.registerDetail
  ) {
    return appPath.user;
  }

  if (
    role === "user" &&
    registerStatus !== "Approved" &&
    pathname === appPath.webinar
  ) {
    return appPath.user;
  }

  if (
    role === "user" &&
    registerStatus === "Registration Required" &&
    studentStatus !== "alumni" &&
    pathname === appPath.user
  ) {
    return appPath.registerDetail;
  }

  if (
    role === "user" &&
    registerStatus !== "Approved" &&
    studentStatus !== "alumni" &&
    pathname !== appPath.user &&
    pathname !== appPath.registerDetail
  ) {
    return appPath.user;
  }

  return null;
};

const PrivateRoute = ({ children, admin, ...rest }: PrivateRouteProps) => {
  const authState = useAuth();
  const currentLocation = useLocation<CustomLocation>();
  const history = useHistory();

  const { registerStatus, role, studentStatus } = authState;

  useEffect(() => {
    if (!studentStatus || !registerStatus) return;
    if (
      role === "user" &&
      registerStatus === "Registration Required" &&
      studentStatus !== "alumni" &&
      currentLocation.pathname !== appPath.registerDetail &&
      currentLocation.state?.from?.pathname !== appPath.registerDetail
    ) {
      history.push(appPath.registerDetail);
    }
  }, [currentLocation, history, registerStatus, role, studentStatus]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const redirectRoute = getRedirectedRoute(
          authState,
          location.pathname,
          admin
        );
        if (redirectRoute === "loading") {
          return <LoadingPage />;
        }
        if (redirectRoute === null) {
          return children;
        }

        return <Redirect to={{ pathname: redirectRoute }} />;
      }}
    />
  );
};

export default PrivateRoute;
