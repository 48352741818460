/** @jsxRuntime classic */
/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/react";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Button, IconButton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo/logo_horizontal.png";
import adminLogo from "../../assets/logo/logo_horizontal_admin.png";
import { UserRole } from "../../authentication/service/useAuth";
import { appPath } from "./navigation";

type Props = {
  role?: UserRole;
  headerTabs?: React.ReactNode;
  toggleDrawer: () => void;
};

const MobileAppBar = ({ role, headerTabs, toggleDrawer }: Props) => {
  const history = useHistory();

  const hasHeaderTabs = Boolean(headerTabs);

  return (
    <AppBar
      position="fixed"
      css={{
        backgroundColor: role === "admin" ? "#0569C4" : undefined,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px 10px",
        "@media print": {
          display: "none",
        },
      }}
    >
      {!headerTabs && (
        <IconButton
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{ color: "white" }}
        >
          <MenuIcon />
        </IconButton>
      )}
      {role === "admin" ? (
        <Button
          css={{
            borderTopRightRadius: "40px",
            borderBottomRightRadius: "40px",
          }}
          onClick={() => history.push(appPath.home)}
        >
          <img
            src={adminLogo}
            alt="logo"
            css={{
              width: "66px",
              height: "30px",
            }}
          />
        </Button>
      ) : (
        <Button onClick={() => history.push(appPath.home)}>
          <img
            src={logo}
            alt="logo"
            css={{
              width: "123px",
              height: "30px",
            }}
          />
        </Button>
      )}
      {hasHeaderTabs && (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {headerTabs}
        </div>
      )}
    </AppBar>
  );
};

export default MobileAppBar;
