import { SvgIcon } from "@mui/material"
import React from "react"
import { IconProps } from "./IconType"

const DrawerWebinarIcon = ({ admin, active = false }: IconProps) => {
  const inactiveColor = admin ? "#fff" : "#9da1b2"
  const activeColor = admin ? "#fff" : "#5694D8"
  return (
    <SvgIcon viewBox="0 0 14.026 16.092" fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14.026"
        height="16.092"
        viewBox="0 0 14.026 16.092"
      >
        <path
          data-name="Path 5539"
          d="M13.198 0H.828A.828.828 0 0 0 0 .829v14.435a.828.828 0 0 0 .828.828h12.37a.828.828 0 0 0 .828-.828V.829A.829.829 0 0 0 13.198 0m-9.31 13.795H2.156v-1.728h1.732Zm0-3.257H2.156V8.813h1.732Zm0-3.256H2.156V5.555h1.732Zm0-3.257H2.156V2.298h1.732Zm7.985 9.277H4.882v-.741h6.987Zm0-3.257H4.882v-.741h6.987Zm0-3.257H4.882v-.741h6.987Zm0-3.257H4.882V2.79h6.987Z"
          fill={active ? activeColor : inactiveColor}
        />
      </svg>
    </SvgIcon>
  )
}

export default DrawerWebinarIcon
