/** @jsxRuntime classic */
/** @jsx  jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/react"
import { Avatar } from "@mui/material"
import React from "react"
import avatar from "../../assets/icon/avatar.svg"
import { useAuth } from "../../authentication/service/useAuth"
import { useUser } from "../../user/service/userService"

const Profile = () => {
  const { user, role } = useAuth()
  const { userDetail } = useUser()

  if (!user) return <></>
  if (!role) return <></>

  const userName = userDetail?.nameTh || user.email
  const name = role === "admin" ? user.email : userName
  const userProfilePicture =
    userDetail?.profilePictures?.length > 0
      ? userDetail.profilePictures[userDetail.profilePictures.length - 1].url
      : undefined

  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        color: "white",
        alignItems: "center",
        columnGap: "12px",
        textTransform: "none",
      }}
    >
      <Avatar
        src={userProfilePicture ?? avatar}
        // alt="avatar"
        sx={{
          width: "38px",
          height: "38px",
        }}
      />
      <div>
        <p>{name}</p>
        <p>{role.toUpperCase()}</p>
      </div>
    </div>
  )
}

export default Profile
