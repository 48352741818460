import { onAuthStateChanged, User } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import client from "../../apiClient";
import { firebaseAuth } from "./firebaseAuthentication";
import {
  RegisterStatus,
  useRegisterStatus,
  StudentStatus,
} from "./registration";

type StatusType = "idle" | "pending" | "success" | "error";
export type UserRole = "admin" | "user";

export type AuthState = {
  status: StatusType;
  user: User | null;
  role?: UserRole;
  registerStatus?: RegisterStatus;
  studentStatus?: StudentStatus;
};

export const AuthContext = createContext<AuthState | null>(null);

export const useAuth = (): AuthState => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be wrapped in a context provider");
  }

  const registerStatus = useRegisterStatus(!context.user);
  const currentUid = context.user?.uid;
  const { data } = useSWR(
    context.status === "success" && Boolean(currentUid)
      ? `user/${currentUid}`
      : null,
    (url) => client.get(url).then((res) => res.data.data)
  );
  const studentStatus = data?.studentStatus ?? null;
  return { ...context, registerStatus, studentStatus };
};

type AuthProviderProps = {
  children: React.ReactNode;
};
export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [auth, setAuth] = useState<AuthState>({
    status: "idle",
    user: null,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (newUser) => {
      if (newUser) {
        mutate("register-status");
        const idTokenResult = await newUser.getIdTokenResult();
        setAuth({
          status: "success",
          user: newUser,
          role: idTokenResult.claims.role as UserRole,
        });
      } else {
        setAuth({
          status: "success",
          user: null,
        });
      }
    });
    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
