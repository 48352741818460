/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react"
import { Link, useLocation } from "react-router-dom"
import { AppNavigation, navigationDetail } from "../component/layout/navigation"
import ThemeColor from "../theme/color"

type ColorType = "default" | "primary" | "secondary"

const getActiveFontColor = (color: ColorType) => {
  switch (color) {
    case "primary":
    case "secondary":
      return "white"
    default:
      return ThemeColor.primary
  }
}

const getHoverFontColor = (color: ColorType) => {
  switch (color) {
    case "primary":
    case "secondary":
      return "white"
    default:
      return ThemeColor.tabSecondaryButton
  }
}

const getInactiveFontColor = (color: ColorType) => {
  switch (color) {
    case "secondary":
      return "#82B4E2"
    default:
      return ThemeColor.tabSecondaryButton
  }
}

const getButtonStyle = (color: ColorType) => {
  return {
    fontSize: color === "default" ? "24px" : "20px",
    fontWeight: 500,
    cursor: "pointer",
    color: getActiveFontColor(color),
    transition: "all 0.3s",
    "@media(max-width: 576px)": {
      fontSize: color === "default" ? "24px" : "16px",
    },
  }
}

type ButtonTabPropType = {
  title: string
  currentPathname: string
  pathname: string
  color: ColorType
}

const ButtonTab = ({
  title,
  currentPathname,
  pathname,
  color = "default",
}: ButtonTabPropType) => {
  const isCurrentRoute = currentPathname === pathname

  const highlightedBorderColor =
    color === "secondary" ? "white" : ThemeColor.tabColor

  return (
    <Link
      to={pathname}
      css={[
        {
          textDecoration: "none",
          backgroundColor: "inherit",
          border: "none",
          minWidth: "105px",
          marginLeft: "20px",
          "@media(max-width: 576px)": {
            minWidth: "80px",
          },
        },
      ]}
    >
      <span
        css={[
          getButtonStyle(color),
          {
            color: isCurrentRoute
              ? getActiveFontColor(color)
              : getInactiveFontColor(color),
            borderBottom: `3px solid ${
              isCurrentRoute ? highlightedBorderColor : "transparent"
            }`,
            ":hover": {
              color: getHoverFontColor(color),
              borderBottom: `3px solid ${highlightedBorderColor}`,
            },
          },
        ]}
      >
        {title}
      </span>
    </Link>
  )
}

export type NavigationLink = {
  label: string
  path: string
}

type ButtonTabGroupProps = {
  appNavigations: AppNavigation[]
  color?: ColorType
  className?: string
}

const AppBarButtonGroup = ({
  appNavigations,
  className,
  color = "default",
}: ButtonTabGroupProps) => {
  const location = useLocation()

  return (
    <div
      className={className}
      css={{
        display: "flex",
      }}
    >
      {appNavigations.map((appNavigation) => (
        <ButtonTab
          key={navigationDetail[appNavigation].label}
          title={navigationDetail[appNavigation].label}
          currentPathname={location.pathname}
          pathname={navigationDetail[appNavigation].path}
          color={color}
        />
      ))}
    </div>
  )
}

export default AppBarButtonGroup
