/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import { UserDetail } from "../../service/userService";
import { ReceiptType } from "./text";

type Props = {
  type: ReceiptType;
  user: UserDetail;
  academicYear: number;
  price: number;
  paymentType: string;
  studentId: string;
};

const Receipt = ({
  type,
  user,
  academicYear,
  price,
  paymentType,
  studentId,
}: Props) => {
  return (
    <div
      css={{
        width: "964px",
        border: "1px solid #D0E0EF",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "22px",
      }}
    >
      <Header type={type} academicYear={academicYear} studentId={studentId} />
      <Content
        user={user}
        type={type}
        price={price}
        paymentType={paymentType}
      />
      <Footer />
      <p css={{ marginTop: "20px", marginBottom: "16px" }}>
        ใบเสร็จรับเงินอิเล็กทรอนิกส์ใช้แทนใบเสร็จรับเงินจริง
      </p>
    </div>
  );
};

export default Receipt;
