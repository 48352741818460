import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LoadingPage from "./component/layout/LoadingPage";
import MaintenancePage from "./component/layout/MaintenancePage";
import { appPath } from "./component/layout/navigation";
import PageWrapper from "./component/layout/PageWrapper";
import PrivateRoute from "./component/layout/PrivateRoute";
import { showExamCard } from "./constant";
import ReceiptPage from "./user/pages/Receipt/ReceiptPage";

const LoginPage = lazy(() => import("./authentication/LoginPage"));
const ForgotPasswordPage = lazy(
  () => import("./authentication/ForgotPasswordPage")
);
const RegisterPage = lazy(() => import("./authentication/RegisterPage"));
const RegisterDetailPage = lazy(() => import("./user/RegisterDetailPage"));
const ManageUserPage = lazy(
  () => import("./user/pages/ManageUserPage/ManageUserPage")
);
const ExaminationFieldPage = lazy(() => import("./user/ExaminationFieldPage"));
const ManageAdminPage = lazy(() => import("./admin/ManageAdminPage"));
const ExamCardPage = lazy(() => import("./user/ExamCardPage"));
const UserInfoPage = lazy(() => import("./user/UserInfoPage/UserInfoPage"));
const ManageUserInfoPage = lazy(
  () => import("./user/UserInfoPage/ManageUserInfoPage")
);
const NewAcademicYearPage = lazy(
  () => import("./newAcademicYear/NewAcademicYearPage")
);
const UserLogPage = lazy(() => import("./log/UserLogPage"));
const EmailPage = lazy(() => import("./email/EmailPage"));
const WebinarPage = lazy(
  () => import("./content/Webinar/pages/WebinarPage/WebinarPage")
);
const ManageWebinarPage = lazy(
  () => import("./content/Webinar/pages/ManageWebinarpage/ManageWebinarPage")
);
const FundamentalSurgeryPage = lazy(
  () =>
    import(
      "./content/FundamentalSurgery/pages/FundamentalSurgeryPage/FundamentalSurgeryPage"
    )
);
const ManageFundamentalSurgeryPage = lazy(
  () =>
    import(
      "./content/FundamentalSurgery/pages/ManageFundamentalSurgeryPage/ManageFundamentalSurgeryPage"
    )
);
const SupplementalPage = lazy(
  () => import("./content/Supplemental/pages/SupplementalPage/SupplementalPage")
);
const ManageSupplementalPage = lazy(
  () =>
    import(
      "./content/Supplemental/pages/ManageSupplementalPage/ManageSupplementalPage"
    )
);
const ManageExamPage = lazy(() => import("./exam/ManageExamPage"));
const AdminExamPage = lazy(() => import("./exam/AdminExamPage"));
const UserExamPage = lazy(() => import("./exam/UserExamPage"));
const HomePage = lazy(() => import("./home/HomePage"));

const isMaintaining = false;

function App() {
  if (isMaintaining) {
    return (
      <BrowserRouter>
        <Suspense fallback={<MaintenancePage />}>
          <Route>
            <MaintenancePage />
          </Route>
        </Suspense>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route exact path={appPath.login}>
            <LoginPage />
          </Route>
          <Route exact path={appPath.forgotPassword}>
            <ForgotPasswordPage />
          </Route>
          <Route exact path={appPath.register}>
            <RegisterPage />
          </Route>
          <PrivateRoute exact path={appPath.registerDetail}>
            <RegisterDetailPage />
          </PrivateRoute>
          {showExamCard && (
            <PrivateRoute path={appPath.examCard}>
              <ExamCardPage />
            </PrivateRoute>
          )}
          <PrivateRoute exact path={appPath.receipt}>
            <ReceiptPage />
          </PrivateRoute>
          <PrivateRoute admin exact path={`${appPath.receipt}/:id`}>
            <ReceiptPage />
          </PrivateRoute>
          <PrivateRoute path={appPath.user}>
            <UserInfoPage />
          </PrivateRoute>
          <PrivateRoute admin exact path={appPath.manageUsers}>
            <ManageUserPage />
          </PrivateRoute>
          <PrivateRoute admin exact path={`${appPath.manageUsers}/:id`}>
            <ManageUserInfoPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.examinationField}>
            <ExaminationFieldPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.manageAdminUsers}>
            <ManageAdminPage />
          </PrivateRoute>
          <PrivateRoute path={appPath.webinar}>
            <WebinarPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.manageWebinar}>
            <ManageWebinarPage />
          </PrivateRoute>
          <PrivateRoute path={appPath.fundamentalSurgery}>
            <FundamentalSurgeryPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.manageFundamentalSurgery}>
            <ManageFundamentalSurgeryPage />
          </PrivateRoute>
          <PrivateRoute path={appPath.supplemental}>
            <SupplementalPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.manageSupplemental}>
            <ManageSupplementalPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.log}>
            <UserLogPage />
          </PrivateRoute>
          <PrivateRoute admin exact path={appPath.manageExam}>
            <ManageExamPage />
          </PrivateRoute>
          <PrivateRoute admin exact path={`${appPath.manageExam}/:id`}>
            <AdminExamPage />
          </PrivateRoute>
          <PrivateRoute exact path={appPath.examResults}>
            <UserExamPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.email}>
            <EmailPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.newAcademicYear}>
            <NewAcademicYearPage />
          </PrivateRoute>
          <PrivateRoute admin path={appPath.homeAdmin}>
            <HomePage />
          </PrivateRoute>
          <PrivateRoute path={appPath.home}>
            <HomePage />
          </PrivateRoute>
          <PrivateRoute path="*">
            <PageWrapper>Loading...</PageWrapper>
          </PrivateRoute>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
