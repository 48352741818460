import { createTheme } from "@mui/material"
import ThemeColor from "./color"

const theme = createTheme({
  typography: {
    fontFamily: "Kanit",
  },
  palette: {
    primary: {
      main: ThemeColor.primary,
    },
    secondary: {
      main: "#0569C4",
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "0 !important",
          marginTop: "10px",
          backgroundColor: `${ThemeColor.lightBlueBackground} !important`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          color: ThemeColor.primary,
          borderBottom: "1px solid white",
          "&:last-child": {
            border: 0,
          },
        },
      },
    },
  },
})

export default theme
