type ReceiptDetail = {
  name: string
  date: string
  paymentType: string
  price: string
  priceText: string
}

export type ReceiptType = "training" | "exam"

export const receiptText: Record<ReceiptType, ReceiptDetail> = {
  training: {
    name: "อบรมศัลยศาสตร์พื้นฐาน",
    date: "ปีการศึกษา 2565-2566",
    paymentType: "ค่าลงทะเบียนอบรมศัลยศาสตร์พื้นฐาน",
    price: "6,000",
    priceText: "(หกพันบาทถ้วน)",
  },
  exam: {
    name: "สอบศัลยศาสตร์พื้นฐาน",
    date: "ปีการศึกษา 2565-2566",
    paymentType: "ค่าลงทะเบียนสอบศัลยศาสตร์พื้นฐาน",
    price: "3,000",
    priceText: "(สามพันบาทถ้วน)",
  },
}
