import { useMemo } from "react";
import useSWR, { mutate } from "swr";
import client from "../apiClient";
import { mapUser, UserDetail } from "../user/service/userService";
import { ExamSummary, ExamResult } from "./type";
import { UserRole } from "../authentication/service/useAuth";

export const postExamResult = async (
  examSummary: ExamSummary,
  examResults: ExamResult[],
  note: string
) => {
  examResults.forEach((examResult) => {
    if (!examResult.email.trim()) {
      throw new Error("invalid file");
    }
  });
  await client.post("/examination-result", {
    examinationSummary: examSummary,
    examination: examResults,
    note,
  });
  mutate("/examination-result");
};

export type AdminExamData = {
  examSummary: ExamSummary;
  examResults: (ExamResult & { user: UserDetail })[];
  note: string;
};

export type SummaryScoreData = {
  examinationResultRoundsSummary: UserSummaryScore | UserSummaryScore[];
};

export type RoundsResult = "Pass" | "Fail";

export type UserSummaryScore = {
  roundsResult: RoundsResult;
  round1PassedAtYear: number | null;
  round2PassedAtYear: number | null;
  round3PassedAtYear: number | null;
  medicalLicense: string;
  nameTh: string;
  nameEn: string;
  studentId: string;
};

export type ExamData = {
  examSummary: ExamSummary;
  examResult: ExamResult & { user: UserDetail };
  note: string;
};

const mapExamResult = (data: any): ExamResult & { user: UserDetail } => {
  return {
    email: data?.email ?? "",
    round1Result: data?.round1Result,
    round1Score: data?.round1Score,
    round2Result: data?.round2Result,
    round2Score: data?.round2Score,
    round3Result: data?.round3Result,
    round3Score: data?.round3Score,
    user: mapUser(data?.user),
  };
};

export const useAdminExamResults = (): AdminExamData => {
  const { data } = useSWR("/examination-result", (url) => {
    return client.get(url).then((res) => {
      return {
        examSummary: res.data.data.examinationSummary,
        examResults: res.data.data.examination.map(mapExamResult),
        note: res.data.data.note,
      };
    });
  });

  return useMemo(
    () =>
      data ?? {
        examSummary: {},
        examResults: [],
        note: "",
      },
    [data]
  );
};

const mapSummaryScore = (data: any): UserSummaryScore => {
  return {
    roundsResult: data?.roundsResult === "not_pass" ? "Fail" : "Pass",
    round1PassedAtYear: data?.round1PassedAtYear,
    round2PassedAtYear: data?.round2PassedAtYear,
    round3PassedAtYear: data?.round3PassedAtYear,
    medicalLicense: data?.medicalLicense,
    nameTh: data?.nameTh,
    nameEn: data?.nameEn,
    studentId: data?.studentId,
  };
};

// มันจะเอาผลที่ Submit ของอันล่าสุดมาเสมอ
// ถ้าเกิด user error อัพโหลดซ้ำ ให้ส่งค่า null ตอน submit ผลสอบไปแทน
export const useSummaryScore = (role: UserRole): SummaryScoreData => {
  const { data } = useSWR("/examination-result/rounds-summary", (url) => {
    return client.get(url).then((res) => {
      const result =
        role === "admin"
          ? {
              examinationResultRoundsSummary:
                res.data.data.examinationResultRoundsSummary.map(
                  mapSummaryScore
                ),
            }
          : {
              examinationResultRoundsSummary:
                res.data.data.examinationResultRoundsSummary,
            };
      return result;
    });
  });

  const defaultValue = useMemo(() => (role === "admin" ? [] : {}), [role]);

  return useMemo(
    () => data ?? { examinationResultRoundsSummary: defaultValue },
    [data, defaultValue]
  );
};

export const useExamResults = (skip = false): ExamData | undefined => {
  const { data } = useSWR(!skip ? "/examination-result" : null, (url) => {
    return client.get(url).then((res) => {
      return {
        examSummary: res.data.data.examinationSummary,
        examResult: mapExamResult(res.data.data?.examination),
        note: res.data.data?.note,
      };
    });
  });

  return data;
};
