import { SvgIcon } from "@mui/material"
import React from "react"
import { IconProps } from "./IconType"

const DrawerQuizIcon = ({ admin, active }: IconProps) => {
  const inactiveColor = admin ? "#fff" : "#9da1b2"
  const activeColor = admin ? "#fff" : "#5694D8"
  return (
    <SvgIcon viewBox="0 0 56.538 35" fontSize="small">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="56.538"
        height="35"
        viewBox="0 0 56.538 35"
      >
        <g transform="translate(-8295.342 -829)">
          <rect
            fill={active ? activeColor : inactiveColor}
            width="56.538"
            height="35"
            rx="3.749"
            transform="translate(8295.342 829)"
          />
          <path
            fill="#172b7a"
            d="M8300.4,845.628a1.263,1.263,0,0,0,.511.476.346.346,0,0,1,.163.166,13.6,13.6,0,0,0,1.8,3.269,8.677,8.677,0,0,0,2.613,2.4,4.844,4.844,0,0,0,5.117-.013,8.553,8.553,0,0,0,2.449-2.2,13.316,13.316,0,0,0,1.94-3.447c.024-.062.054-.145.1-.17a1.6,1.6,0,0,0,.691-.78,5.115,5.115,0,0,0,.518-1.862,3.388,3.388,0,0,0-.129-1.541,1.217,1.217,0,0,0-.279-.438.318.318,0,0,1-.113-.27,10.586,10.586,0,0,0-.22-2.925,6.431,6.431,0,0,0-2.479-3.883,7.441,7.441,0,0,0-3.481-1.312c-.285-.038-.573-.064-.86-.1h-1.126c-.084.011-.168.026-.253.032a8.483,8.483,0,0,0-2.979.736,6.083,6.083,0,0,0-3.5,3.948,23.885,23.885,0,0,0-.472,2.506c-.063.378-.081.764-.12,1.156a.64.64,0,0,0-.416.406,1.915,1.915,0,0,0-.112.334,4.486,4.486,0,0,0,.018,1.914A4.275,4.275,0,0,0,8300.4,845.628Zm.731-2.708a.853.853,0,0,1,.05-.249c.12-.3.284-.365.6-.225.018-.095.033-.187.053-.278a3.989,3.989,0,0,1,.86-1.861,2.639,2.639,0,0,1,2.426-.9c.357.041.7.19,1.058.261a7.388,7.388,0,0,0,3.7-.132,2.6,2.6,0,0,1,.877-.019,3.306,3.306,0,0,1,1.341.413c.263.134.522.271.783.406l.038-.028-.282-.788a.592.592,0,0,1,.394.194,5.315,5.315,0,0,1,.934,1.693c.124.342.228.691.341,1.036.336-.14.507-.065.624.294a2.654,2.654,0,0,1,.015,1.461,6.316,6.316,0,0,1-.335.933,1.075,1.075,0,0,1-.227.309.4.4,0,0,1-.514.1c-.1.254-.186.511-.289.761a9.642,9.642,0,0,1-2.707,3.835,4.789,4.789,0,0,1-2.1,1.036,3.792,3.792,0,0,1-2.585-.391,7.11,7.11,0,0,1-2.469-2.256,10.986,10.986,0,0,1-1.465-2.883c-.012-.035-.026-.069-.04-.1a.4.4,0,0,1-.55-.124,2.212,2.212,0,0,1-.5-1.067,4.8,4.8,0,0,1-.063-.8C8301.1,843.338,8301.116,843.128,8301.131,842.92Z"
          />
          <rect
            fill="#172b7a"
            width="27"
            height="2"
            transform="translate(8320.342 837)"
          />
          <rect
            fill="#172b7a"
            width="27"
            height="2"
            transform="translate(8320.342 842)"
          />
          <rect
            fill="#172b7a"
            width="27"
            height="2"
            transform="translate(8320.342 847)"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default DrawerQuizIcon
