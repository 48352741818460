import useSWR from "swr";
import dayjs, { Dayjs } from "dayjs";
import client from "../../apiClient";

export const signup = async (
  email: string,
  password: string
): Promise<void> => {
  return client.post("user", {
    email,
    password,
  });
};

export type RegisterStatus =
  | "Waiting For Upload"
  | "Approved"
  | "Pending"
  | "Registration Required"
  | null;

export type StudentStatus = "current" | "alumni" | "expired" | null;

export const getRegisterStatus = async (): Promise<RegisterStatus> => {
  const response = await client.get("/register-status");
  return response.data.data?.status;
};

export const useRegisterStatus = (skip: boolean): RegisterStatus => {
  const { data } = useSWR(skip ? null : "/register-status", (url) =>
    client.get(url).then((res) => res.data.data?.status)
  );
  return data;
};

export const checkRegistrationExpired = (endDate: Dayjs | null) => {
  const now = dayjs();
  const isExpired = now.diff(endDate) > 0;
  return isExpired;
};
