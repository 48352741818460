/** @jsxRuntime classic */
/** @jsx jsx */
/* @jsxFrag React.Fragment */
import { jsx } from "@emotion/react"
import React from "react"
import { useMediaQuery } from "@mui/material"
import ThemeColor from "../../theme/color"

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:1000px)")
  return (
    <div
      css={{
        backgroundColor: ThemeColor.primary,
        padding: "44px 124px",
        color: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        "@media(max-width: 1000px)": {
          padding: "20px",
        },
        "@media print": {
          display: "none",
        },
      }}
    >
      <div
        css={{
          "@media(max-width: 1000px)": {
            fontSize: "8px",
          },
        }}
      >
        <p
          css={{
            fontSize: "22px",
            "@media(max-width: 1000px)": {
              fontSize: "10px",
            },
          }}
        >
          ราชวิทยาลัยศัลยแพทย์แห่งประเทศไทย
        </p>
        <div css={{ border: "1px solid #3F8AE2", margin: "8px 0" }} />
        <p
          css={{
            fontSize: "20px",
            "@media(max-width: 1000px)": {
              fontSize: "10px",
            },
          }}
        >
          Royal College of Surgeons of Thailand
        </p>
        <p>อาคารเฉลิมพระบารมี 50 ปี เลขที่ 2 ซอบศูนย์วิจัย</p>
        <p>ถนนเพชรบุรีตัดใหม่ กรุงเทพฯ 10310</p>
        <div
          css={{
            marginTop: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>โทรศัพท์ : 0-2716-6141-3</p>
          <p>โทรสาร : 0-2176-6144</p>
        </div>
      </div>
      <iframe
        css={{ border: 0, borderRadius: "30px" }}
        title="google map"
        width={isMobile ? "150" : "400"}
        height={isMobile ? "100" : "200"}
        // style="border:0"
        loading="lazy"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB_GvGXJio77P36eK-0A2sKDrbYaEyD9mk
    &q=place_id:ChIJ430-1lqe4jAR9C8mnZf-cFg"
      />
    </div>
  )
}

export default Footer
