/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react"
import LogoutIcon from "@mui/icons-material/Logout"
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material"
import { User } from "firebase/auth"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import background from "assets/background/background_light.jpeg"
import { logout } from "authentication/service/firebaseAuthentication"
import { useAuth } from "authentication/service/useAuth"
import { logError } from "logger/logger"
import ThemeColor from "theme/color"
import Footer from "./Footer"
import { AppNavigation, navigationDetail, useAppNavigation } from "./navigation"
import Profile from "./Profile"
import RCSTAppBar from "./RCSTAppBar"

const appBarDesktopHeight = 80
const appBarMobileHeight = 72
const drawerWidth = 264
interface PageWrapperProp {
  children?: React.ReactNode
  hideFooter?: boolean
  className?: string
  headerTabs?: React.ReactNode
}

type DrawerContentProps = {
  admin: boolean
  onLogout: () => void
  toggleDrawer: () => void
  user: User | null
  navigations: AppNavigation[]
}
const DrawerContent = ({
  admin,
  onLogout,
  toggleDrawer,
  user,
  navigations,
}: DrawerContentProps) => {
  const activeColor = admin ? "#0F81D6" : "#254187"
  const history = useHistory()
  return (
    <Box
      color="primary"
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      css={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 60px)",
        justifyContent: "space-between",
        backgroundColor: admin ? "#5694D8" : undefined,
      }}
    >
      <List>
        {user && (
          <ListItem css={{ backgroundColor: activeColor }}>
            <Profile />
          </ListItem>
        )}
        {navigations.map((navigation) => {
          const { label, path, icon: Icon } = navigationDetail[navigation]
          return (
            <ListItem
              key={label}
              css={{
                backgroundColor:
                  history.location.pathname === path ? activeColor : undefined,
                borderBottom: "1px solid #6EBDF4",
              }}
              button
              onClick={() => history.push(path)}
            >
              <ListItemIcon
                css={{ paddingLeft: "9px", boxSizing: "border-box" }}
              >
                {Icon && (
                  <Icon
                    admin={admin}
                    active={history.location.pathname === path}
                  />
                )}
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItem>
          )
        })}
      </List>
      {user && (
        <List>
          <Divider />
          <ListItem
            css={{ backgroundColor: admin ? "#D0E0EF" : "#254187" }}
            button
            onClick={onLogout}
          >
            <ListItemIcon>
              <LogoutIcon css={{ color: admin ? "#0F81D6" : undefined }} />
            </ListItemIcon>
            <ListItemText
              primary="LOG OUT"
              css={{ color: admin ? "#0F81D6" : undefined }}
            />
          </ListItem>
        </List>
      )}
    </Box>
  )
}

const PageWrapper = ({
  children,
  headerTabs,
  className,
  hideFooter = false,
}: PageWrapperProp) => {
  const isMobile = useMediaQuery("(max-width:1000px)")
  const [isDrawerOpen, setisDrawerOpen] = useState(false)
  const toggleDrawer = () => setisDrawerOpen(!isDrawerOpen)

  const { user, role } = useAuth()
  const { drawerNavigationList } = useAppNavigation()

  const onLogout = async () => {
    try {
      await logout()
    } catch (error) {
      logError("Cannot sign out", error as Error)
    }
  }

  return (
    <React.Fragment>
      <RCSTAppBar
        role={role}
        headerTabs={headerTabs}
        toggleDrawer={toggleDrawer}
        onLogout={onLogout}
      />
      <div
        css={{
          marginTop: `${isMobile ? appBarMobileHeight : appBarDesktopHeight}px`,
        }}
      >
        {isMobile && !headerTabs && (
          <Drawer
            anchor="left"
            variant="persistent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                zIndex: 99,
                width: drawerWidth,
                boxSizing: "border-box",
                marginTop: `${appBarMobileHeight}px`,
                backgroundColor: ThemeColor.primary,
                color: "white",
              },
            }}
            open={isDrawerOpen}
            onClose={toggleDrawer}
          >
            <DrawerContent
              admin={role === "admin"}
              onLogout={onLogout}
              toggleDrawer={toggleDrawer}
              user={user}
              navigations={drawerNavigationList}
            />
          </Drawer>
        )}
        <div
          css={{
            backgroundSize: "cover",
            backgroundImage:
              role !== "admin" ? `url(${background})` : undefined,
            backgroundColor: role === "admin" ? "#D0E0EF" : undefined,
            display: "flex",
            justifyContent: "center",
            minHeight: `calc(100vh - ${
              isMobile ? appBarMobileHeight : appBarDesktopHeight
            }px - ${hideFooter || role === "admin" ? 0 : 86}px)`,
          }}
          className={className}
        >
          {children}
        </div>
        {!hideFooter && role !== "admin" && <Footer />}
      </div>
    </React.Fragment>
  )
}

export default PageWrapper
