import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material"
import StylesProvider from "@mui/styles/StylesProvider"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import { SWRConfig } from "swr"
import App from "./App"
import { AuthProvider } from "./authentication/service/useAuth"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import theme from "./theme/theme"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://56813c3ffa0d4d7a99568a635429f0e4@o1030290.ingest.sentry.io/5997583",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const options = { errorRetryCount: 0 }

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <SWRConfig value={options}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </SWRConfig>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
