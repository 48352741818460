import axios from "axios"
import * as authentication from "./authentication/service/firebaseAuthentication"

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL ?? "",
  timeout: 5000,
})

client.interceptors.request.use(
  async (config) => {
    const token = await authentication.getToken()
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default client
