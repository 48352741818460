/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React from "react";
import RecipientSignature from "assets/logo/recipient_signature.jpeg";
import TreasurerSignature from "assets/logo/treasurer_signature.png";
import ReceiptLogo from "assets/logo/receipt_logo.png";
import Signature from "./Signature";

const Footer = () => {
  return (
    <div css={{ position: "relative" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "344px",
        }}
      >
        <Signature image={RecipientSignature} text="ผู้รับเงิน" />

        <Signature image={TreasurerSignature} text="เหรัญญิก" />
      </div>
      <div css={{ height: "16px" }} />
      <img
        src={ReceiptLogo}
        alt="logo"
        width={104}
        css={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      />
    </div>
  );
};

export default Footer;
