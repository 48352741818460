import { useMediaQuery } from "@mui/material"
import React from "react"
import { UserRole } from "../../authentication/service/useAuth"
import DesktopAppBar from "./DesktopAppBar"
import MobileAppBar from "./MobileAppBar"

type Props = {
  role?: UserRole
  headerTabs?: React.ReactNode
  toggleDrawer: () => void
  onLogout: () => void
}

const RCSTAppBar = ({ role, headerTabs, toggleDrawer, onLogout }: Props) => {
  const isMobile = useMediaQuery("(max-width:1000px)")

  return isMobile ? (
    <MobileAppBar
      role={role}
      headerTabs={headerTabs}
      toggleDrawer={toggleDrawer}
    />
  ) : (
    <DesktopAppBar role={role} headerTabs={headerTabs} onLogout={onLogout} />
  )
}

export default RCSTAppBar
