const ThemeColor = {
  primary: "#172B7A",
  primaryText: "#0569C4",
  labelText: "#575E6D",
  alertText: "#EA2027",
  tabSecondaryButton: "#777A89",
  tabColor: "#0071BC",
  grayBackground: "#F2F2F2",
  lightBlueBackground: "#D0E0EFDD",
  lighterBlueBackground: "#F2F6FB",
}

// #0F81D6
// #F2F6FB
// #9DA1B2
// #cccccc
// #254187
// #1285D8
// #BFDAEE
// #D9DCDD
// #78D2FF

export default ThemeColor
